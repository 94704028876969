import React, {
  useRef,
  useState,
  useEffect,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
  useMemo,
} from "react";
import {
  Input,
  Form,
  Button,
  Divider,
  Checkbox,
  Popup,
  Modal,
  DropdownProps,
  Dropdown,
  Icon,
  Pagination,
  Dimmer,
  Loader,
  TextArea,
} from "semantic-ui-react";
import PureReactTable, { formatPrice, formatComma } from "react-lib/apps/common/PureReactTable";
import * as Util from "react-lib/utils";
import moment from "moment";
import * as CONSTANT from "react-lib/utils/constant";
import CardLayout from "react-lib/apps/common/CardLayout";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

import CardProductSearch from "react-lib/apps/BIL/CardProductSearch";

import SearchBox from "react-lib/apps/common/SearchBox";
import ComboBox from "react-lib/apps/common/ComboBox";
import RightLabel from "react-lib/apps/common/RightLabel";
import DateTextBox from "react-lib/apps/common/DateTextBox";

import ModInfoError from "react-lib/apps/common/ModInfo";
import ModInfoSuccess from "react-lib/apps/common/ModInfo";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { RowInfo, Column, RowRenderProps } from "react-table-6";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";

import { beStringToAdString } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";
import { useIntl } from "react-intl";

const styles = {
  reactTable: {
    height: "400px",
  },
};

type CardOtherBillProps = {
  controller: any;
  onGetSearchOptions: any;
  patientData: any;
  postMiscellaneousOrderPreview: any;
  getEligibilityTypeChoices: any;
  getHospitalFee: any;
  encounterId: number;
  getMiscellaneousOrder: any;
  getClinicalTerm: any;
  deleteMiscellaneousOrder: any;
  saveMiscellaneousOrder: any;
  onClose: any;
  toggleable: boolean;
  isFilterByDate: boolean;
  onEvent: any;
  userTokenize: any;
  setProp: any;
  readOnly: boolean;
  selectedDivision?: Record<string, any> | null;
  errorMessage?: any;
  buttonLoadCheck?: any;
};

const PRICE_LEVEL_OPTIONS = [
  { key: "LEVEL_1", value: "LEVEL_1", text: "ระดับ 1" },
  { key: "LEVEL_2", value: "LEVEL_2", text: "ระดับ 2" },
  { key: "LEVEL_3", value: "LEVEL_3", text: "ระดับ 3" },
  { key: "CUSTOM", value: "CUSTOM", text: "แพทย์กำหนดเอง" },
];

// const ADJUST_REMARK = [
//   "ตรวจโดยไม่ได้รักษา",
//   "งานตัดไหม",
//   "งานไม่ฟุ้งกระจาย",
//   "ให้ส่วนลดผู้ป่วยพิเศษในโครงการวิจัย",
//   "ใช้ในงานวิจัย",
//   "ใส่ฟันซ่อม",
//   "lab/อุปกรณ์ไม่พร้อม",
//   "คนรู้จัก",
//   "คนไข้เก่า",
//   "ญาติ",
//   "ผู้ป่วย VIP",
//   "มีปัญหาเรื่องค่าใช้จ่าย",
//   "ผู้ป่วยไม่มีอาการแล้ว",
//   "ผู้ป่วยได้สิทธิสังคมสงเคราะห์",
//   "ประกอบการเรียนการสอน",
//   "อื่นๆ",
// ];

const CardOtherBill = (props: CardOtherBillProps) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModLoading, setIsModLoading] = useState(false);
  const [buttonState, setButtonState] = useState("");
  const [error, setError] = useState<boolean | null>(false);
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [eligibilityChoices, setEligibilityChoices] = useState<any[]>([]);
  const [hospitalFee, setHospitalFee] = useState<any[]>([]);
  const [selectedCell, setSelectedCell] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [claimablePrice, setClaimablePrice] = useState(0);
  const [nonClaimablePrice, setNonClaimablePrice] = useState(0);
  const [startDate, setStartDate] = useState(Util.formatDate(moment()));
  const [endDate, setEndDate] = useState(Util.formatDate(moment()));
  const [chkFilterDate, setChkFilterDate] = useState(true);
  const [openModInfoError, setOpenModInfoError] = useState(false);
  const [openModInfoSuccess, setOpenModInfoSuccess] = useState(false);
  const [alertText, setAlertText] = useState<any>(null);
  const [openModProduct, setOpenModProduct] = useState(false);
  const [pageLimit, setPageLimit] = useState(20);
  const [numberOfPage, setNumberOfPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [cancelReason, setCancelReason] = useState<string>("")
  const [adjustRemarkList, setAdjustRemarkList] = useState<string[]>([])

  const searchBoxRef = useRef<any>();
  const inputRef = useRef<any[]>([]);

  // const [modConfirmData, setModConfirmData] = useState<any>({ open: false, product: null });
  const [openModPriceLevel, setOpenModPriceLevel] = useState<any>({});
  const [adjustRemark, setAdjustRemark] = useState("");
  const [customPrice, setCustomPrice] = useState("");

  const ADJUST_REMARK_OPTIONS = useMemo(() => {
    let currentRemark = (previewData || []).map((i: any) => i.adjusted_remark?.trim());
    let combile = Array.from(
      new Set([...adjustRemarkList, ...currentRemark, ...[adjustRemark]].filter((i) => !!i))
    );
    console.log("previewData", previewData);
    return combile.map((i) => ({ key: i, value: i, text: i }));
  }, [previewData, adjustRemark, adjustRemarkList]);

  // const popupRef = useRef<any>();

  // useEffect(() => {
  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // });

  useEffect(() => {
    if (["SUCCESS", "ERROR"].includes(buttonState)) {
      setTimeout(() => {
        setButtonState("");
      }, 2000);
    }
  }, [buttonState]);

  // function handleClickOutside(event) {
  // console.log(event.target, 'event')
  // if(popupRef && popupRef.current
  //   //  && !popupRef.current.contains(event.target)
  //    ){
  //     console.log( popupRef.current, ' popupRef.current')
  //   setSelectedCell({})
  // }
  // if (inputRef.current &&
  //   inputRef.current.inputRef && inputRef.current.inputRef.current &&
  //   !inputRef.current.inputRef.current.contains(event.target) &&
  // (itemRef.current && !itemRef.current.contains(event.target))
  // ) {
  //   setVisible(false)
  // }
  // }

  useEffect(() => {
    getHospitalFee();
    getEligibilityTypeChoices();
    getClinicalTerm();
    refresh();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    calculatePrice();
  }, [previewData]);

  useEffect(() => {
    refresh();
  }, [pageLimit]);

  useEffect(() => {
    refresh(false);
  }, [currentPage]);

  console.log("CardOtherBill selectedCell", selectedCell);

  const allowedEditPriceLevel = useMemo(() => {
    const useRights: string[] = props.selectedDivision?.useRights || [];

    return useRights.includes("แก้ไขระดับราคาและส่วนลดค่าบริการ");
  }, [props.selectedDivision]);

  const columns = [
    {
      Header: "No",
      accessor: "no",
      minWidth: 20,
      width: 40,
      Cell: (row: RowRenderProps) => {
        // console.log('CardOtherBill 1 row: ', row);
        return currentPage * pageLimit + (row?.index || 0) + 1;
      },
    },
    {
      Header: "Service Code",
      accessor: "service_code",
      width: 100,
    },
    {
      Header: "Product Name",
      accessor: "name",
      width: 300,
    },
    {
      Header: "จำนวนสั่ง",
      accessor: "quantity",
      width: 90,
      getProps: (finalState: any, rowInfo: RowInfo, column: Column, instance: any) => {
        // console.log('CardOtherBill 2 getProps finalState: ', finalState);
        // console.log('CardOtherBill 2 getProps rowInfo: ', rowInfo);
        // console.log('CardOtherBill 2 getProps instance: ', instance);
        // console.log('CardOtherBill 2 getProps column: ', column);
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.quantity) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            cursor: "pointer",
            backgroundColor:
              rowInfo &&
              !hospitalFee.includes(rowInfo?.original?.code) &&
              rowInfo?.original?.can_edit !== false
                ? "rgb(255, 255, 204)"
                : null,
          },
        };
      },
      Cell: (row: RowRenderProps) => {
        // console.log('CardOtherBill 3 row: ', row);
        if (hospitalFee.includes(row.original.code) || row.original.can_edit === false) {
          return <> {parseFloat(row.value)} </>;
        } else if (row.index === selectedCell.quantity && typeof row.index === "number") {
          return (
            <>
              <Input
                id={`CardOtherBill-Item-Quantity-${row.index}`}
                ref={(el) => (inputRef.current[row.index as number] = el)}
                style={{ width: "-webkit-fill-available" }}
                placeholder={row.value}
                onChange={(e) => {
                  const { value } = e.target;
                  handleSetPreviewData({
                    field: "quantity",
                    index: row.index,
                    value,
                  });
                }}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                  console.log("onBlur: ");
                  setSelectedCell({});
                }}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                    if (
                      isNaN(previewData[row.index as number]?.quantity) ||
                      parseFloat(previewData[row.index as number].quantity) < 0
                    ) {
                      handleSetPreviewData({
                        field: "quantity",
                        index: row.index,
                        value: row.value,
                      });
                      return setSelectedCell({});
                    }
                    handleChangeQuantity();
                  } else if (e.keyCode === CONSTANT.KEY_CODE.ESCAPE) {
                    handleSetPreviewData({
                      field: "quantity",
                      index: row.index,
                      value: row.value,
                    });
                    setSelectedCell({});
                  }
                }}
              />
              <span style={{ fontSize: "x-small" }}>{intl.formatMessage({ id: "Enter เพื่อคำนวณ" })}</span>
            </>
          );
        } else {
          return parseFloat(row.value);
        }
      },
    },

    {
      Header: "ระดับราคา",
      accessor: "original_price_level",
      width: 90,
      show: CONFIG.ENABLE_PRICE_LEVEL,
      getProps: (finalState: any, rowInfo: RowInfo) => {
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.original_price_level) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            backgroundColor: rowInfo && allowedEditPriceLevel ? "rgb(255, 255, 204)" : null,
            cursor: "pointer",
          },
        };
      },
      Cell: (row: RowRenderProps) => {
        const priceLevel =
          row.original?.adjusted_price_level || row?.original?.original_price_level || "";

        const foundPriceLevel = PRICE_LEVEL_OPTIONS.find((option) => option.value === priceLevel);

        return (
          <>
            <Popup
              key={`charges_date_${row.original.id}`}
              position="bottom center"
              // ref={popupRef}
              hideOnScroll
              on="click"
              onClose={(e) => {
                setSelectedCell({});
              }}
              open={row.index === selectedCell.original_price_level}
              trigger={<div>{foundPriceLevel?.text || ""}</div>}
              disabled={!allowedEditPriceLevel}
              content={
                <Dropdown
                  selection
                  search
                  options={PRICE_LEVEL_OPTIONS}
                  // disabled={!props.treatmentPerform?.product}
                  value={
                    row.original?.adjusted_price_level || row?.original?.original_price_level || ""
                  }
                  onChange={(e, { value }) => {
                    console.log("Dropdown PRICE_LEVEL_OPTIONS onChange !! value: ", value);
                    setSelectedCell({});
                    // TODO: set Default to remark, price
                    setAdjustRemark("");
                    setCustomPrice("");
                    setOpenModPriceLevel({
                      id: row?.original?.id,
                      priceLevel: value,
                      open: true,
                    });
                  }}
                />
              }
            />
          </>
        );
      },
    },

    {
      Header: "เหตุผล",
      accessor: "adjusted_remark",
      width: 90,
      show: CONFIG.ENABLE_PRICE_LEVEL,
      getProps: (finalState: any, rowInfo: RowInfo) => {
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.adjusted_remark) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            backgroundColor: rowInfo && allowedEditPriceLevel ? "rgb(255, 255, 204)" : null,
            cursor: "pointer",
          },
        };
      },
      Cell: (row: RowRenderProps) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              disabled={!(row?.original?.adjusted_remark && allowedEditPriceLevel)}
              color="yellow"
              size="mini"
              icon
              onClick={() => {
                setAdjustRemark(row?.original?.adjusted_remark);
                setCustomPrice("");
                setOpenModPriceLevel({
                  open: true,
                  id: row?.original?.id,
                });
              }}
            >
              <Icon name="edit" />
            </Button>
          </div>
        );
      },
    },

    {
      Header: "หน่วยสั่ง",
      accessor: "unit",
      width: 90,
    },
    {
      Header: "ราคาต่อหน่วย",
      accessor: "price_unit",
      width: 100,
      getProps: (state: any, rowInfo: RowInfo, column: Column) => {
        console.log("rowInfo: ", rowInfo);
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.price_unit) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            cursor: "pointer",
            backgroundColor:
              rowInfo &&
              (rowInfo?.original?.adjusted_price_level ||
                rowInfo?.original?.original_price_level ||
                "") === "CUSTOM"
                ? "rgb(255, 255, 204)"
                : null,
          },
        };
      },

      Cell: (row: RowRenderProps) => {
        return (
          <div
            onClick={() => {
              if (
                row &&
                (row?.original?.adjusted_price_level ||
                  row?.original?.original_price_level ||
                  "") === "CUSTOM"
              ) {
                setCustomPrice(row.original.custom_level_price);
                setAdjustRemark(row.original.adjusted_remark);

                setOpenModPriceLevel({
                  id: row.original.id,
                  open: true,
                  priceLevel: "CUSTOM",
                });
              }
            }}
          >
            {" "}
            {formatPrice(row?.original?.price_unit)}{" "}
          </div>
        );
      },
    },
    {
      Header: "ราคารวม",
      accessor: "price_total",
      width: 100,
      Cell: (row: RowRenderProps) => formatPrice(row.value),
    },
    {
      Header: "เบิกได้",
      accessor: "price_claimable",
      width: 100,
      Cell: (row: RowRenderProps) => formatPrice(row.value),
    },
    {
      Header: "วันที่ค่าใช้จ่าย",
      accessor: "charges_date",
      width: 100,
      getProps: (finalState: any, rowInfo: RowInfo) => {
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.charges_date) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            backgroundColor: rowInfo ? "rgb(255, 255, 204)" : null,
            cursor: "pointer",
          },
        };
      },
      Cell: (row: RowRenderProps) => {
        return (
          <>
            <Popup
              key={`charges_date_${row.original.id}`}
              flowing
              position="bottom center"
              // ref={popupRef}
              hideOnScroll
              on="click"
              onClose={(e) => {
                setSelectedCell({});
              }}
              open={row.index === selectedCell.charges_date}
              trigger={<div>{row.value}</div>}
              content={
                <Form>
                  <Form.Group inline style={{ margin: "0px" }}>
                    <Form.Field>วันที่</Form.Field>
                    <Form.Field>
                      <DateTextBox
                        value={row.value}
                        onChange={(date: string) => {
                          handleSetPreviewData({
                            field: "charges_date",
                            index: row.index,
                            value: date,
                          });

                          setSelectedCell({});
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Button
                        color="red"
                        icon="trash"
                        onClick={() => {
                          handleSetPreviewData({
                            field: "charges_date",
                            index: row.index,
                            value: "",
                          });

                          setSelectedCell({});
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              }
            />
          </>
        );
      },
    },
    {
      Header: "วันที่และเวลาที่บันทึก",
      accessor: "edited_user_date",
      width: 150,
    },
    {
      Header: "แพทย์อ่านผล",
      accessor: "doctor_name",
      width: 150,
      show: CONFIG.ENABLE_PRICE_LEVEL,
      getProps: (state: any, rowInfo: RowInfo, column: Column) => {
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.doctor_name) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            cursor: "pointer",
            ...(rowInfo &&
              rowInfo.original.p_type_code === "XRAY" && { backgroundColor: "rgb(255, 255, 204)" }),
          },
        };
      },
      Cell: (row: RowRenderProps) => {
        return row.original?.p_type_code === "XRAY" ? (
          <Popup
            key={`doctor_name${row.original.id}`}
            flowing
            position="bottom center"
            open={row.index === selectedCell.doctor_name}
            trigger={<div>{row.original?.doctor_name || "-"}</div>}
            hideOnScroll
            on="click"
            onClose={(e) => {
              if (props.userTokenize?.employeeName) {
                handleSetPreviewData({
                  field: "doctor_name",
                  index: row.index,
                  value: props.userTokenize?.employeeName,
                });
                handleSetPreviewData({
                  field: "imaging_result_doctor",
                  index: row.index,
                  value: props.userTokenize?.doctorId,
                });
              }

              setSelectedCell({});
              props.setProp("userTokenize", {
                ...props.userTokenize,
                token: "",
                employeeName: "",
                loading: false,
                error: null,
              });
            }}
            onOpen={() => {
              props.setProp("userTokenize", {
                ...props.userTokenize,
                token: "",
                employeeName: "",
                loading: false,
                error: null,
              });
            }}
            content={
              <>
                <EmployeeToken
                  placeholder={intl.formatMessage({ id: "รหัสผู้บันทึก" })}                  onEnterToken={(code) => {
                    props.onEvent({
                      message: "UserTokenize",
                      params: {
                        action: "GetDoctorCode",
                        code: code,
                      },
                    });

                    // props.onEvent({
                    //   message: "HandleGetDeliverDrugTokenization",
                    //   params: { code },
                    // });
                  }}
                  onClearToken={() => {
                    props.setProp("userTokenize", {
                      ...props.userTokenize,
                      token: "",
                      employeeName: "",
                      loading: false,
                      error: null,
                    });
                  }}
                  error={!!props.userTokenize?.error}
                  loading={props.userTokenize?.loading}
                  employeeName={props.userTokenize?.employeeName}
                />
              </>
            }
          />
        ) : (
          <> </>
        );

        // console.log('CardOtherBill imaging_result_doctor row.original.p_type_code: ', row.original.p_type_code);
        // console.log('CardOtherBill imaging_result_doctor row.index: ', row.index);
        // if (row.original.p_type_code === "XRAY") {
        //   <Popup
        //     flowing
        //     position="bottom center"
        //     open={row.index === selectedCell.imaging_result_doctor}
        //     trigger={<div>OK</div>}
        //     hideOnScroll
        //     onClose={e => {
        //       setSelectedCell({});
        //     }}
        //     content={
        //       <> TEST </>
        //     // <EmployeeToken
        //     //   placeholder={"รหัสผู้บันทึก"}
        //     //   onEnterToken={(code) => {
        //     //     props.onEvent({
        //     //       message: "UserTokenizeTable",
        //     //       params: {
        //     //         action: "CheckUserToken",
        //     //         code: code,
        //     //         idx: row.index
        //     //       },
        //     //     });

        //     //     // props.onEvent({
        //     //     //   message: "HandleGetDeliverDrugTokenization",
        //     //     //   params: { code },
        //     //     // });
        //     //   }}
        //     //   onClearToken={() =>
        //     //     props.setProp("userTokenize", {
        //     //       ...props.userTokenize,
        //     //       token: "",
        //     //       employeeName: "",
        //     //       loading: false,
        //     //       error: null,
        //     //     })
        //     //   }
        //     //   // error={!!props.userTokenize?[row.index]?.error}
        //     //   // loading={props.userTokenize?[row.index]?.loading}
        //     //   employeeName={"doctor"}
        //     //   />
        //     }
        //     />
        //   } else {
        //     return <> - </>
        //   }
      },
    },
    {
      Header: "Eligibility Type",
      accessor: "eligibility_type",
      width: 150,
      getProps: (state: any, rowInfo: RowInfo, column: Column) => {
        console.log("rowInfo: ", rowInfo);
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.eligibility_type) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            cursor: "pointer",
            backgroundColor:
              rowInfo &&
              !hospitalFee.includes(rowInfo.original.code) &&
              rowInfo?.original?.can_edit !== false
                ? "rgb(255, 255, 204)"
                : null,
          },
        };
      },
      Cell: (row: RowRenderProps) => {
        let index = eligibilityChoices.findIndex(
          (item) => item.id === row.original.eligibility_type
        );

        let text = row.original.eligibility_type ? row.original.eligibility_type : "";
        if (index > -1) {
          text = eligibilityChoices[index].text;
        }
        if (hospitalFee.includes(row.original.code) || row.original.can_edit === false) {
          return text;
        }
        return (
          <Popup
            key={`eligibility_type_${row.original.id}`}
            flowing
            position="bottom center"
            open={row.index === selectedCell.eligibility_type}
            trigger={<div>{text}</div>}
            hideOnScroll
            on="click"
            onClose={(e) => {
              setSelectedCell({});
            }}
            content={
              <ComboBox
                options={eligibilityChoices}
                value={row.value}
                onValueChange={(value: DropdownProps["value"]) =>
                  handleChangeEligibilityType({
                    value,
                    row: row.original,
                    index: row.index,
                    quantityChange: true,
                  })
                }
              />
            }
          />
        );
      },
    },
    {
      Header: "เบิกไม่ได้",
      accessor: "price_non_claimable",
      width: 100,
      Cell: (row: RowRenderProps) => formatPrice(row.value),
    },
    {
      Header: "ลบ",
      accessor: "remove",
      minWidth: 55,
      className: "centerText",
      sortable: false,
      Cell: (row: RowRenderProps) => {
        return <Button id={`CardOtherBill-Item-Delete-${row.index}`} icon="minus" size="mini" color="red" onClick={() => setConfirmModal(true)} />;
      },
    },
  ];

  const getMiscellaneous = async ({ searchText, limit, offset }: any = {}) => {
    if (props.controller) {
      const [data, error] = await props.controller.handleGetMiscellaneous({
        encounterId: props.encounterId,
        searchText,
        groupCode: "MISC",
        limit,
        offset,
      });
      return [data, error];
    } else {
      const [data, error] = await props.onGetSearchOptions({
        searchText,
        groupCode: "MISC",
        limit,
        offset,
      });
      return [data, error];
    }
  };

  const generatePageOptions = () => {
    // let buttonList = [];

    return (
      <Pagination
        boundaryRange={0}
        defaultActivePage={1}
        activePage={currentPage + 1}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
        totalPages={numberOfPage}
        onPageChange={(event, { activePage }) => {
          handleChangePage({ page: (activePage as number) - 1 });
        }}
      />
    );

    for (let i = 0; i < numberOfPage; i++) {
      buttonList.push(
        <Button
          key={i}
          icon
          {...(currentPage === i && { color: "blue" })}
          content={i + 1}
          onClick={() => handleChangePage({ page: i })}
        />
      );
    }
    // return buttonList;
  };

  const handleChangePage = ({ page }: { page: number }) => {
    setCurrentPage(page);
  };

  const getMiscellaneousOrder = async () => {
    setIsLoading(true);
    setAlertText(null);
    let params = {
      encounterId: props.encounterId,
      active: true,
      orderBy: "-charges_date",
      limit: pageLimit,
      offset: currentPage * pageLimit,
    };
    if (chkFilterDate) {
      params.fromDate = startDate;
      params.toDate = endDate;
    }
    var miscellaneousOrderData = null;
    var miscellaneousOrderError = null;
    if (props.controller) {
      [miscellaneousOrderData, miscellaneousOrderError] =
        await props.controller.getMiscellaneousOrder({ ...params });
    } else {
      [miscellaneousOrderData, miscellaneousOrderError] = await props.getMiscellaneousOrder({
        ...params,
      });
    }

    setIsLoading(false);

    if (!isMounted.current) {
      return;
    }
    if (miscellaneousOrderError) {
      setAlertText(miscellaneousOrderError);
    } else if (miscellaneousOrderData && miscellaneousOrderData.items) {
      setNumberOfPage(Math.trunc(miscellaneousOrderData.total / pageLimit) + 1);
      setPreviewData(miscellaneousOrderData.items);
    }
    return [miscellaneousOrderData, miscellaneousOrderError];
  };

  const refresh = (clearPage?: boolean) => {
    if (clearPage !== false) {
      setCurrentPage(0);
    }
    getMiscellaneousOrder();
  };

  const calculatePrice = () => {
    let priceTotal = 0;
    let priceClaimable = 0;
    let priceNonClaimable = 0;
    for (let item of previewData) {
      priceTotal += item.price_total;
      priceClaimable += item.price_claimable;
      priceNonClaimable += item.price_non_claimable;
    }
    setTotalPrice(priceTotal);
    setClaimablePrice(priceClaimable);
    setNonClaimablePrice(priceNonClaimable);
  };

  const handleChangeQuantity = () => {
    handlePreviewData({
      data: {
        items: previewData,
        quantityChange: true,
      },
      newRow: false,
    });
    console.log("setSlectedCell({})");
    setSelectedCell({});
  };

  const handleChangeLevelReasonPrice = async (data) => {
    console.log("handleChangeLevelReasonPrice data: ", data, previewData);

    let newArr = JSON.parse(JSON.stringify(previewData));
    let idx = newArr.findIndex((i: any) => i.id === data.id);
    if (idx !== -1) {
      if ("priceLevel" in data) {
        newArr[idx].adjusted_price_level = data.priceLevel;
      }
      if ("adjustRemark" in data) {
        newArr[idx].adjusted_remark = data.adjustRemark;
      }
      if ("customPrice" in data) {
        newArr[idx].custom_level_price = data.customPrice;
      }

      setIsModLoading(true);

      await handlePreviewData({
        data: {
          items: newArr,
          quantityChange: true,
        },
        newRow: false,
        noLoading: true
      });

      setIsModLoading(false);
    }
  };

  const handleChangeEligibilityType = ({ value, row, index, quantityChange }: any) => {
    let newArr = [...previewData];
    let data = { ...newArr[index] };
    data.eligibility_type = value;
    newArr[index] = data;
    handlePreviewData({
      data: {
        items: newArr,
        quantityChange,
      },
      newRow: false,
    });
    console.log("setSlectedCell({})");
    setSelectedCell({});
  };

  const handleSetPreviewData = async ({ field, index, value }) => {
    console.log("handleSetPreviewData: ", field, index, value);
    let newArr = previewData;
    let data = { ...newArr[index] };
    data[field] = value;
    newArr[index] = data;
    setPreviewData(newArr);
  };

  const getHospitalFee = async () => {
    let list = "core_HOSPITAL_FEE_REGULAR,core_HOSPITAL_FEE_SPECIAL,core_HOSPITAL_FEE_PREMIUM";
    const [hospitalFeeData, hospitalFeeError] = await props.getHospitalFee({
      list,
    });
    if (isMounted.current) {
      let newArr: any[] = [];
      if (hospitalFeeData && hospitalFeeData.result) {
        newArr = Object.values(hospitalFeeData.result);
      }
      setHospitalFee(newArr);
    }
  };

  const getEligibilityTypeChoices = async () => {
    var choicesData = null;
    var choicesError = null;
    if (props.controller) {
      [choicesData, choicesError] = await props.controller.getChoices({
        model: "MSD.SupplyOrderItem",
        field: "eligibility_type",
        nameAsId: true,
      });
    } else {
      [choicesData, choicesError] = await props.getEligibilityTypeChoices({
        model: "MSD.SupplyOrderItem",
        field: "eligibility_type",
        nameAsId: true,
      });
    }
    if (isMounted.current) {
      if (choicesData) {
        setEligibilityChoices(choicesData);
      }
    }
  };

  const getClinicalTerm = async () => {
    if (!props.controller) {
      const [result] = await props.getClinicalTerm({
        type: "price_change_reason",
      });

      setAdjustRemarkList(result.map((item: any) => item.name));
    }
  };

  const handlePreviewData = async ({ data, newRow = false, noLoading = false }: any = {}) => {
    if (!noLoading) {
      setIsLoading(true);
    }

    var orderPreviewData = null;
    var orderPreviewError = null;
    if (props.controller) {
      [orderPreviewData, orderPreviewError] = await props.controller.postMiscellaneousOrderPreview({
        encounterId: props.encounterId,
        data,
      });
    } else {
      [orderPreviewData, orderPreviewError] = await props.postMiscellaneousOrderPreview({ data });
    }

    setIsLoading(false);

    if (isMounted.current) {
      if (orderPreviewError) {

        setAlertText(orderPreviewError);

        return;
      } else if (orderPreviewData) {
        let tranformData = [...orderPreviewData];
        for (let item of tranformData) {
          if (!item.id) {
            item.id = "fakeId" + item.product + item.perform_datetime;
          }
        }
        if (newRow) {
          setPreviewData([...previewData, ...tranformData]);
        } else {
          setPreviewData(tranformData);
        }
      }
      setOpenModPriceLevel({});
      setSelectedCell({});
      return [orderPreviewData, orderPreviewError];
    } else {
      return [];
    }
  };

  const handleSelectItem = async ({ item, id, quantityChange = false } = {}) => {
    let data = {};
    let items = item;
    if (!Array.isArray(item)) {
      items = [{ ...item }];
    }
    for (let eachItem of items) {
      eachItem.product = eachItem.id;
      eachItem.quantity = 1;
      eachItem.price_total = eachItem.price_unit;
      eachItem.charges_date = Util.formatDate(moment());
      eachItem.eligibility_type = "TREATMENT";
      eachItem.encounter =
        props.patientData && props.patientData.ENCOUNTER
          ? props.patientData.ENCOUNTER.encounter_id
          : null;
      delete eachItem["id"];
    }
    data.quantityChange = quantityChange;
    data.items = items;
    await handlePreviewData({ data, newRow: true });
  };

  const handleApproveCancel = async () => {
    let index = Object.values(selectedCell)[0];
    let newData = [...previewData];
    if (index || index === 0) {
      let indexId = previewData[index].id ? previewData[index].id.toString() : "";
      if (indexId.includes("fakeId")) {
        newData.splice(index, 1);
        setPreviewData(newData);

        handleCloseModCancel();
      } else {
        setIsModLoading(true);

        const [deleteMiscellaneousData, deleteMiscellaneousError] = await deleteMiscellaneousOrder({
          id: previewData[index].id,
          data: { note: cancelReason },
          noLoading: true,
        });

        setIsModLoading(false);

        if (isMounted.current) {
          if (!deleteMiscellaneousData) {
            setAlertText(deleteMiscellaneousError);

            return;
          } else {
            newData.splice(index, 1);
            setPreviewData(newData);
          }
        }

        handleCloseModCancel();
      }
    }
  };

  const deleteMiscellaneousOrder = async ({ id, data, noLoading = false } = {}) => {
    if (!noLoading) {
      setIsLoading(true);
    }

    var deleteMiscellaneousData = null;
    var deleteMiscellaneousError = null;
    if (props.controller) {
      [deleteMiscellaneousData, deleteMiscellaneousError] =
        await props.controller.deleteMiscellaneousOrder({ id, data });
    } else {
      [deleteMiscellaneousData, deleteMiscellaneousError] = await props.deleteMiscellaneousOrder({
        id,
        data,
      });
    }

    setIsLoading(false);

    if (isMounted.current) {
      if (deleteMiscellaneousError) {
        setAlertText(deleteMiscellaneousError[0]);
        setOpenModInfoError(true);
        // setError(deleteMiscellaneousError)
      }
      return [deleteMiscellaneousData, deleteMiscellaneousError];
    } else {
      return [];
    }
  };

  const handleSaveData = async () => {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    // setIsLoading(true);
    setButtonState("LOADING");
    let saveData = [...previewData];
    for (let item of saveData) {
      let itemId = item.id ? item.id.toString() : "";
      if (itemId.includes("fakeId")) {
        item.id = null;
      }

      // การบันทึกหรือแก้ไข item.charges_date_iso จะเป็น MM/DD/YYYY
      if (item.charges_date_iso) {
        item.charges_date_iso = beStringToAdString(item.charges_date);
      }
    }
    var saveMiscellaneousData = null;
    var saveMiscellaneousError = null;
    if (props.controller) {
      [saveMiscellaneousData, saveMiscellaneousError] =
        await props.controller.saveMiscellaneousOrder({
          saveData: {
            miscellaneous_orders: saveData,
          },
        });
    } else {
      [saveMiscellaneousData, saveMiscellaneousError] = await props.saveMiscellaneousOrder({
        saveData: {
          miscellaneous_orders: saveData,
        },
      });
    }
    if (isMounted.current) {
      if (saveMiscellaneousError) {
        setAlertText(saveMiscellaneousError);
        setOpenModInfoError(true);
        setButtonState("ERROR");
      } else if (saveMiscellaneousData) {
        // setOpenModInfoSuccess(true);
        setButtonState("SUCCESS");
        refresh();
      }
      // setIsLoading(false);
      return [saveMiscellaneousData, saveMiscellaneousError];
    } else {
      setButtonState("");
      return [];
    }
  }

  const handleCloseModCancel = ()=> {
    setConfirmModal(false);
    setCancelReason("");
    setSelectedCell({});
  }

  const handleOpenModProduct = async () => {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    setOpenModProduct(true);
  };

  return (
    <CardLayout
      headerColor="yellow"
      titleText={intl.formatMessage({ id: "บันทึกรายการค่าใช้จ่ายเบ็ดเตล็ด" })}
      testid="crdOtherBill"
      loading={isLoading}
      toggleable={props.toggleable}
      onClose={props.onClose}
    >
      {typeof alertText == "string" && openModInfoError ? (
        <ModInfoError
          titleColor="red"
          titleName={intl.formatMessage({ id: "เกิดข้อผิดพลาด" })}
          btnText={intl.formatMessage({ id: "ปิด" })}
          open={openModInfoError}
          alertText={alertText}
          onApprove={() => setOpenModInfoError(false)}
        />
      ) : (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            setAlertText("");
            setOpenModInfoError(false);
          }}
          error={alertText}
          success={null}
          languageUX={props.languageUX}
        />
      )}
      <ModInfoSuccess
        titleColor="green"
        titleName={intl.formatMessage({ id: "บันทึกสำเร็จ" })}
        btnText={intl.formatMessage({ id: "ตกลง" })}
        open={openModInfoSuccess}
        onApprove={() => setOpenModInfoSuccess(false)}
      />
      <ModConfirm
        openModal={confirmModal}
        titleName={selectedCell?.id?.includes("fakeId") ? "ยกเลิกรายการ" : "ระบุเหตุผลยกเลิกรายการ"}
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        denyButtonText={intl.formatMessage({ id: "ไม่" })}
        closeIcon
        disabledApproveButton={!cancelReason && !selectedCell?.id?.includes("fakeId")}
        // Callback
        onDeny={handleCloseModCancel}
        onApprove={handleApproveCancel}
        onCloseWithDimmerClick={handleCloseModCancel}
        // Element
        content={
          <>
            <Dimmer active={isModLoading} inverted>
              <Loader inverted></Loader>
            </Dimmer>
            {selectedCell?.id?.includes("fakeId") ? (
              <div style={{ marginBottom: "-0.5rem" }}>
                {intl.formatMessage({ id: "ต้องการลบรายการใช่หรือไม่" })}
              </div>
            ) : (
              <Form style={{ margin: "-0.5rem 0" }}>
                <div style={{ paddingBottom: "0.5rem" }}>
                  เหตุผลยกเลิกรายการค่าใช้จ่าย<span style={{ color: "red" }}>*</span>
                </div>
                <TextArea value={cancelReason} onChange={(e, v) => setCancelReason(v.value)} />
              </Form>
            )}
          </>
        }
      />
      <Modal open={openModProduct} size="large">
        <CardProductSearch
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          hideCallback={() => setOpenModProduct(false)}
          onGetItem={getMiscellaneous}
          onSelectItems={handleSelectItem}
        />
      </Modal>

      <ModConfirm
        openModal={!!openModPriceLevel?.open}
        titleName={intl.formatMessage({ id: "ระบุราคาและเหตุผลสำหรับการแก้ไข" })}
        backgroundColor={"red"}
        content={
          <>
            <Dimmer active={isModLoading} inverted>
              <Loader inverted></Loader>
            </Dimmer>
            {openModPriceLevel?.priceLevel === "CUSTOM" ? (
              <>
                <div style={{ margin: "4px 0px" }}>
                  {" "}
                  ระบุราคา <span style={{ color: "red" }}>*</span>
                </div>
                <Input
                  fluid
                  value={customPrice}
                  onChange={(e, { value }) => {
                    setCustomPrice(value);
                  }}
                />
              </>
            ) : null}
            <div style={{ margin: "4px 0px" }}>
              {" "}
              เหตุผลในการเปลี่ยนแปลงราคา <span style={{ color: "red" }}>*</span>
            </div>
            <Dropdown
              placeholder={intl.formatMessage({ id: "กรุณาเลือกเหตุผล" })}
              search
              selection
              allowAdditions
              additionLabel={intl.formatMessage({ id: "เพิ่ม:" })}
              fluid
              options={ADJUST_REMARK_OPTIONS}
              value={adjustRemark} // || props.treatmentPerform?.adjusted_remark}
              onAddItem={(e, { value }) => {
                setAdjustRemark(value as string);
              }}
              onChange={(e, { value }) => {
                setAdjustRemark(value as string);
              }}
            />
          </>
        }
        onApprove={() => {
          handleChangeLevelReasonPrice({
            id: openModPriceLevel?.id,
            ...(openModPriceLevel?.priceLevel && { priceLevel: openModPriceLevel?.priceLevel }),
            ...(adjustRemark && { adjustRemark }),
            ...(customPrice && { customPrice }),
          });

          // props.handleEvent({
          //   message: "handleUpdateTreatmentPlanIssue",
          //   params: {
          //     pk: props.treatmentPerform?.id,
          //     exam_id: props.treatmentPerform?.treatment_plan?.examination,
          //     plan_id: props.treatmentPerform?.treatment_plan?.id,
          //     ...(openModPriceLevel?.priceLevel && { adjusted_price_level: openModPriceLevel?.priceLevel }),
          //     ...((customPrice || props.treatmentPerform?.customPrice) && { custom_level_price: customPrice || props.treatmentPerform?.customPrice }),
          //     ...((adjustRemark || props.treatmentPerform?.adjusted_remark) && { adjusted_remark: adjustRemark || props.treatmentPerform?.adjusted_remark }),
          //   },
          // });
        }}
        onDeny={() => {
          // set back to original value
          setOpenModPriceLevel({ open: false });
        }}
        onCloseWithDimmerClick={() => {
          // set back to original value
          setOpenModPriceLevel({ open: false });
        }}
      ></ModConfirm>

      <Form>
        <ErrorMessage errors={error} />
        <Form.Group inline>
          <Form.Field width={15}>
            <Form.Field>
              <label style={{ minWidth: "max-content" }}>
                {intl.formatMessage({ id: "ชื่อรายการ" })}
              </label>
            </Form.Field>
            <Form.Field width={15}>
              <SearchBox
                id="CardOtherBill-SearchBox-Miscellaneous"
                ref={searchBoxRef}
                testid="sbxMiscellaneous"
                inputClassName="greyDisabled"
                textField="name"
                onGetSearchOptions={getMiscellaneous}
                // onGetSearchOptions={({searchText} = {}) => props.onGetSearchOptions({searchText, groupCode: 'MISC'})}
                onSelectOption={handleSelectItem}
              />
            </Form.Field>
          </Form.Field>
          <Form.Field>
            <Button
              id="CardOtherBill-Button-Search"
              fluid
              color="blue"
              testid="btnSearchMiscellaneous"
              onClick={handleOpenModProduct}
            >
              ค้นหา
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider />
      <Form>
        <Form.Group inline className="rowReverse">
          <Button
            id="CardOtherBill-Button-Refresh"
            icon="refresh"
            color="blue"
            style={{ marginRight: "1rem" }}
            onClick={getMiscellaneousOrder}
          />
          <Form.Field width={7}>
            <DateTextBox 
              id="CardOtherBill-DateTextBox-EndDate"
              testid="dtbEnd"
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Field>
          <label>-</label>
          <Form.Field width={7}>
            <DateTextBox
              id="CardOtherBill-DateTextBox-StartDate"
              testid="dtbStart"
              value={startDate}
              onChange={(date: any) => setStartDate(date)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              id="CardOtherBill-Checkbox-Date"
              testid="chkDate"
              label={intl.formatMessage({ id: "วันที่ค่าใช้จ่าย" })}
              checked={chkFilterDate}
              style={{ minWidth: "max-content" }}
              onChange={(e, { checked }) => setChkFilterDate(checked as boolean)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        className="--rt-tbody-overflow-y-hidden"
        style={styles.reactTable}
        selectedClassName="blueSelectedRow"
        showPagination={false}
        columns={columns}
        data={previewData}
        manual={false}
        pageSize={previewData ? (previewData.length < 8 ? 8 : previewData.length) : 8}
        getTdProps={(state: any, rowInfo: RowInfo, column: Column, instance: any) => {
          return {
            onClick: (e: MouseEvent<HTMLButtonElement>, handleOriginal: any) => {
              console.log("CardOtherBill onClick");
              e.stopPropagation();
              if (props.readOnly) {
                console.log("readonly then return");
                return;
              }
              console.log("onClick !! ");
              if (rowInfo && selectedCell[column.id] !== rowInfo.index) {
                console.log("setSelectedCell", column.id, rowInfo.index);
                setSelectedCell({
                  [column.id]: rowInfo.index,
                  id: rowInfo.original.id?.toString(),
                });
              }
            },
          };
        }}
        onSelect={(originalRow) => setSelectedRow(originalRow)}
        selectedRow={selectedRow}
      />
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <ComboBox
              id="CardOtherBill-ComboBox-PageNum"
              testid="cboPageNum"
              selection
              options={[
                { text: 10, key: 10, value: 10 },
                { text: 20, key: 20, value: 20 },
                { text: 30, key: 30, value: 30 },
                { text: 40, key: 40, value: 40 },
                { text: 50, key: 50, value: 50 },
              ]}
              value={pageLimit}
              onChange={(e, { value }) => setPageLimit(value)}
            />
          </Form.Field>
          <Form.Field>{generatePageOptions()}</Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <RightLabel>{intl.formatMessage({ id: "รวม" })}</RightLabel>
          </Form.Field>
          <Form.Field width={3}>
            <Input
              id="CardOtherBill-Input-TotalPrice"
              testid="txtTotalPrice"
              className="priceInput"
              readOnly
              value={formatComma(totalPrice)}
            />
          </Form.Field>
          <Form.Field>
            <RightLabel style={{ minWidth: "max-content" }}>
              {intl.formatMessage({ id: "เบิกได้" })}
            </RightLabel>
          </Form.Field>
          <Form.Field width={3}>
            <Input
              id="CardOtherBill-Input-ClaimablePrice"
              testid="txtClaimablePrice"
              className="priceInput"
              readOnly
              value={formatComma(claimablePrice)}
            />
          </Form.Field>
          <Form.Field>
            <RightLabel style={{ minWidth: "max-content" }}>
              {intl.formatMessage({ id: "เบิกไม่ได้" })}
            </RightLabel>
          </Form.Field>
          <Form.Field width={3}>
            <Input
              id="CardOtherBill-Input-NonClaimablePrice"
              testid="txtNonClaimablePrice"
              className="priceInput"
              readOnly
              value={formatComma(nonClaimablePrice)}
            />
          </Form.Field>
          <Form.Field style={{ flex: 1 }} />
          <Form.Field width={3}>
            <ButtonLoadCheck
              // function
              id="btn-confirmData"
              setProp={props.setProp}
              onClick={handleSaveData}
              // data
              buttonLoadCheck={buttonState}
              color="green"
              title={intl.formatMessage({ id: "บันทึก" })}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </CardLayout>
  );
};

CardOtherBill.defaultProps = {
  controller: null,
  onGetSearchOptions: () => [null, null],
  patientData: {},
  postMiscellaneousOrderPreview: () => [null, null],
  getEligibilityTypeChoices: () => [null, null],
  getHospitalFee: () => [null, null],
  getClinicalTerm: () => [null, null],
  encounterId: null,
  getMiscellaneousOrder: () => [null, null],
  deleteMiscellaneousOrder: () => [null, null],
  saveMiscellaneousOrder: () => [null, null],
  onClose: () => {},
  isFilterByDate: true,
  toggleable: true,
};

export default React.memo(CardOtherBill);
